import { Box, Grid, TextField } from '@mui/material';
import ButtonCustom from 'components/ButtonCustom';
import ImageDropZone from 'components/ImageDropZone';
import Restrict from 'components/Restrict';
import Spinner from 'components/Spinner';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { addOrganisation, updateOrganisation } from 'services/business-setting-service';
import { USER_ROLES } from 'utils/constants';
import { IOrganisationUpdate } from 'utils/contracts';
import { getErrorHtmlContent } from 'utils/utils';

const BusinessSetting = ({ refetch, mode, organisationCallback, dataOrganisation }: any) => {
  const { role, setOrganisation } = useContext(UseAuthContext);
  const { t } = useTranslation();
  const [preview, setPreview] = useState(null);
  const [image_url, setImageUrl] = useState(null);
  const [image_error, setImageError] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (dataOrganisation && mode !== 'create') {
      setName(dataOrganisation?.company_name || '');
      setAddress(dataOrganisation?.company_address || '');
      setImageUrl(dataOrganisation?.image_url ?? null);
    }
  }, [dataOrganisation, mode]);

  const readOnly = useMemo(() => {
    return role &&
      role !== USER_ROLES.ADMIN &&
      ![USER_ROLES.MANAGER].includes(role)
      ? true
      : false;
  }, [role]);

  const onImageDrop = (files: any, maxSize: any) => {
    const file = files[0];
    let reader = new FileReader();

    reader.onloadend = (e: any) => {
      setPreview(e.target.result);
      setImageUrl(null);
    };

    if (file) {
      if (file.size > maxSize) {
        setImageError(t('IMAGE_SIZE_ERROR'));
        return;
      }
      reader.readAsDataURL(file);
    }
  };

  const removeImage = (e: any) => {
    e.preventDefault();
    setPreview(null);
    setImageUrl(null);
    e.stopPropagation();
  };

  const onClickHandler = async (e: any) => {
    e.preventDefault();
    try {
      setisLoading(true);
      let response;
      if (mode === 'create') {
        response = await addOrganisation({
          image_url: image_url ?? '',
          company_name: name,
          company_address: address,
          preview: preview,
        });
      } else {
        response = await updateOrganisation({
          company_email: dataOrganisation.company_email,
          company_phone: dataOrganisation.company_phone,
          image_url: image_url || undefined,
          company_name: name,
          id: dataOrganisation?.id,
          company_address: address,
          preview: preview,
        } as IOrganisationUpdate);
      }

      if (response.status === 200) {
        queryClient.invalidateQueries(['getOrganisationbyId']);
        setOrganisation(response.data);
        setisLoading(false);
        enqueueSnackbar(t('BUSINESS_SETTING_UPDATE_SUCESS'), {
          variant: 'success',
        });
        if (mode === 'create') {
          organisationCallback(response.data.id);
        }
      }
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box sx={{ display: "flex", gap: "30px", flexDirection: "column", justifyContent: "flex-start" }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: {sm: "30px", xs: "30px"},
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", sm: "480px" },
        }}
          className="tab-content-form"
        >
          <TextField
            fullWidth
            variant='standard'
            id='name'
            name='name'
            type='text'
            autoFocus
            label={t('EDIT_BUSINESS_SETTING_NAME')}
            autoComplete='off'
            autoCorrect='off'
            helperText={t('EDIT_BUSINESS_SETTING_NAME_HELPER_TEXT')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={readOnly}
          />
          <TextField
            fullWidth
            variant='standard'
            id='address'
            name='address'
            type='text'
            label={t('EDIT_BUSINESS_SETTING_ADDRESS')}
            autoComplete='off'
            autoCorrect='off'
            helperText={t('EDIT_BUSINESS_SETTING_ADDRESS_HELPER_TEXT')}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={readOnly}
          />
        </Box>
        <Box sx={{ display: "flex", width: {sm:"62%", md:"50%"}, justifyContent:{sm:'flex-start', md:'flex-start'} }}>
          <ImageDropZone
            onImageDrop={onImageDrop}
            preview={preview}
            image_url={image_url}
            onImageRemove={removeImage}
            enabled={!readOnly}
            size={500000}
            image_error={image_error}
            customStyle={{ height: '100%', width: '170px', margin: "0px" }}
          />
        </Box>
      </Box>
      <Restrict forbidden={['viewer']}>
        <Grid textAlign={"justify"} display={"flex"} justifyContent={{sm:"flex-start", md:"flex-start"}}>
          <ButtonCustom
            color='primary'
            type='submit'
            variant='contained'
            onClick={onClickHandler}
          >
            {t('EDIT_BUSINESS_SETTING_SAVE_BUTTON')}
          </ButtonCustom>
        </Grid>
      </Restrict>
    </Box>
  );
};

export default BusinessSetting;
