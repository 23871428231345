import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material';
import SignUP from 'assets/join-bg.png';
import ButtonCustom from 'components/ButtonCustom';
import PasswordTextField from 'components/PasswordTextField';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { signupUsingInvitation } from 'services/invitation-signup';
import { getErrorHtmlContent } from 'utils/utils';

const SignUpByInvite = () => {
  const location: any = useLocation().state;
  const navigate = useNavigate();
  const [email, setEmail] = useState(location?.email);
  const [orgID, setOrgID] = useState(location?.organisationID);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const { t } = useTranslation();
  const { loginAfterInvitationProcess } = useContext(UseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (location === null || !location.email || !location.organisationID) {
      navigate('/', {
        replace: true,
      });
    }
  }, []);

  const signUpHandler = async (e: any) => {
    try {
      e.preventDefault();
      setisLoading(true);
      const response = await signupUsingInvitation({
        email: email,
        password: password,
        name: name,
        allow_policy: terms,
        allow_mailing: receiveEmail,
        organization: orgID,
      });
      await loginAfterInvitationProcess(response.data);
      setisLoading(false);
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        justifyContent: 'space-between',
        mt: "50px"
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "80%" } }} >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: "10px",
            width: { xs: "100%", md: "80%" }
          }}
        >
          <Typography variant='h1' sx={{ marginBottom: "30px" }}>{t('SIGNUP_INVITE_HEADER')}</Typography>
          {/*           <Typography variant='h3'>{t('SIGNUP_INVITE_HEADER2')}</Typography>
 */}        </Box>
        <Box component='form' sx={{ width: { xs: "100%", md: "80%" } }}
          className='tab-content-form '>
          <TextField
            fullWidth
            variant='standard'
            id='full_name'
            name='full_name'
            autoFocus
            label={t('INVITE_SIGNUP_NAME')}
            helperText={t('INVITE_SIGNUP_NAME_HELPER_TEXT')}
            autoComplete='off'
            autoCorrect='off'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField

            variant='standard'
            fullWidth
            name='email'
            label={t('INVITE_SIGNUP_EMAIL')}
            helperText={t('INVITE_SIGNUP_EMAIL_HELPER_TEXT')}
            id='email'
            type='email'
            autoComplete='off'
            autoCorrect='off'
            disabled={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PasswordTextField
            name='password'
            sx={{ '& .MuiInputBase-formControl': { letterSpacing: "4px" } }}

            label={t('INVITE_SIGNUP_PASSWORD')}
            fullWidth
            variant='standard'
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <Box sx={{ gap: 0 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.2rem',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      fontSize: "16px"
                    }}
                    value={terms}
                    onChange={(event: any, checked: boolean) => {
                      setTerms(checked);
                    }}
                  />
                }
                sx={{
                  mr: '0rem',
                  fontSize: "16px"
                }}
                label={t('INVITE_SIGNUP_TERMS')}
              />
              <a
                style={{ fontSize: "16px" }}
                href='https://designbuilder.co.il/policy/'
                target='_blank'
                className='term_link'
              >
                {t('INVITE_SIGNUP_TERMS_LINK')}
              </a>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={receiveEmail}
                  onChange={(event: any, checked: boolean) => {
                    setReceiveEmail(checked);
                  }}
                />
              }
              sx={{ fontSize: "16px !important" }}
              label={t('INVITE_SIGNUP_EMAIL_RECEIVE')}
            />

          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <ButtonCustom
              color='primary'
              type='submit'
              variant='contained'
              onClick={signUpHandler}
            >
              {t('INVITE_SIGNUP_BUTTON')}
            </ButtonCustom>

          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", }}>
        <Box
          component='img'
          src={SignUP}
          sx={{
            width: { xs: "200px", md: '420px' },
            height: 'auto',
            objectFit: 'contain'
          }}
        />
      </Box>

    </Box>
  );
};

export default SignUpByInvite;
