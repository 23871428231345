import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseIcon from '@mui/icons-material/Close'; // Ajout de l'importation de l'icône de fermeture
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, List, ListItem, TextField, Typography } from '@mui/material';
import DragIcon from 'assets/DragIcon';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import ButtonCustom from './ButtonCustom';
import ButtonIcon from './ButtonIcon';
import ButtonIconText from './ButtonIconText';

export interface Dimension {
  id: any;
  visible: boolean;
  title: string;
  isEditable?: boolean;
}

export interface DimensionSelectorProps {
  categories: Dimension[];
  onToggle: (updatedCategories: Dimension[]) => void;
  open: boolean;
  handleClose: () => void;
}

export const DimensionFilter = ({ categories, onToggle, open, handleClose }: DimensionSelectorProps) => {
  const [localCategories, setLocalCategories] = useState<Dimension[]>([]);
  const [editCategoryId, setEditCategoryId] = useState<any | null>(null);
  const [editTitle, setEditTitle] = useState<string>('');
  const [editMode, setEditMode] = useState(true);
  const textFieldRef = useRef<HTMLInputElement | null>(null); // Ajout d'un useRef pour le TextField
  const [isDragging, setIsDragging] = useState(false); // État pour suivre le glissement
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null); // État pour suivre l'index de l'élément en cours de glissement
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  
  const handleDialogClose = () => {
    handleClose();
        setTimeout(() => {
      const nonFixedCategories = categories.slice(3, -3);
      setLocalCategories(nonFixedCategories);
      setEditCategoryId(null);
      setEditTitle('');
      setEditMode(true);
    }, 300);
  };

  useEffect(() => {
    const nonFixedCategories = categories.slice(3, -3);
    setLocalCategories(nonFixedCategories);
  }, [categories]);

  const handleChange = (categoryId: any) => {
    const category = localCategories.find(cat => cat.id === categoryId);
    const currentVisibleCount = localCategories.filter(cat => cat.visible).length;

    // Si on essaie d'activer une colonne
    if (!category?.visible && currentVisibleCount >=10) {
      enqueueSnackbar(t('FIELDS_LIMIT_10'), {
        variant: 'error',
      });
      return;
    }

    const updatedCategories = localCategories.map(category =>
      category.id === categoryId
        ? { ...category, visible: !category.visible }
        : category
    );

    setLocalCategories(updatedCategories);
  };




  const handleEditText = (categoryId: any, newTitle: string) => {
    const updatedCategories = localCategories.map(category =>
      category.id === categoryId ? { ...category, title: newTitle } : category
    );
    setLocalCategories(updatedCategories);
  };

  const handleSave = () => {
    // Vérifier s'il y a des titres vides
    const hasEmptyTitles = localCategories.some(category => !category.title.trim());
    if (hasEmptyTitles) {
      enqueueSnackbar(t('LAYOUT_EMPTY_TITLE_ERROR'), {
        variant: 'error',
      });
      return;
    }
    // Vérification des doublons de titres
    const titles = localCategories.map(category => category.title);
    const hasDuplicates = titles.some((title, index) => titles.indexOf(title) !== index);

    if (hasDuplicates) {
      enqueueSnackbar(t('LAYOUT_DUPLICATE_TITLE_ERROR'), {
        variant: 'error',
      });
      return;
    }

    setIsUpdating(true);
    
    const updatedCategories = [
      ...categories.slice(0, 3),
      ...localCategories,
      ...categories.slice(-3)
    ];

    onToggle(updatedCategories);
    
    // Désactiver le loader après la mise à jour
    setTimeout(() => {
      setIsUpdating(false);
      handleClose();
    }, 1000);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    setDraggingIndex(null)
    const reorderedCategories = Array.from(localCategories);
    const [removed] = reorderedCategories.splice(result.source.index, 1);
    reorderedCategories.splice(result.destination.index, 0, removed);

    setLocalCategories(reorderedCategories);
  };

  const handleAddColumn = () => {
    const currentVisibleCount = localCategories.filter(cat => cat.visible).length;

    // Vérifier si le nombre de colonnes visibles est inférieur à 7 avant d'ajouter une nouvelle colonne
    if (currentVisibleCount >= 10) {
      enqueueSnackbar(t('FIELDS_LIMIT_10'), {
        variant: 'error',
      });
      return;
    }

    const newCategory: Dimension = {
      id: `new_${Date.now()}`,
      visible: true,
      title: '',
      isEditable: true
    };
    setTimeout(() => {
      textFieldRef.current?.focus(); // Focaliser le TextField après l'ajout
    }, 0);
    setLocalCategories([newCategory, ...localCategories]);
    setEditCategoryId(newCategory.id);
    setEditTitle('');
  };

  const handleDeleteCategory = (categoryId: any) => {
    setLocalCategories(prevCategories => {
      const updatedCategories = prevCategories.filter(category => category.id !== categoryId);
      return updatedCategories;
    });
  };

  return (
    <>
      <Dialog onClose={handleDialogClose} open={open} fullWidth={true} sx={{
        '& .MuiDialog-paper': {
          width: '500px',
          boxShadow: 'none',
          height: "700px",
          backgroundColor: "white"

        },
      }}>
        <Box sx={{
          minHeight: '91px',
          display: 'flex',
          position: 'relative',
          alignItems: "center",
          padding: "0 20px"
        }}>
          <Typography variant="h2" sx={{
            textAlign: 'left'
          }}>
            {t("REVISION_GRAPH_MODIFY_DIMENSION")}
          </Typography>
          <Box sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}>
            <ButtonIconText
              icon={<div style={{ fontSize: "40px", display: "flex", alignItems: "center" }}><AddRoundedIcon fontSize='inherit' /></div>}
              color='primary'
              disableElevation={true}
              type='submit'
              variant='contained'
              sx={{ minWidth: '176px', }}
              onClick={handleAddColumn}
            >
              {t("REVISION_GRAPH_ADD_DIMENSION")}
            </ButtonIconText>
          </Box>
        </Box>
        <ButtonIcon
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </ButtonIcon>
        <DialogContent sx={{ padding: '0px', width: 'auto' }}>
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={(start) => setDraggingIndex(start.source.index)} // Définir l'index de l'élément en cours de glissement
            onDragUpdate={() => setDraggingIndex(draggingIndex)} // Maintenir l'index pendant le glissement
          >
            <Droppable droppableId="categories">
              {(provided) => (
                <List
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "white",
                    width: "93%",
                    margin: "0 auto", // Centrer la boîte horizontalement
                  }}
                >
                  {localCategories.map((category, index) => (
                    <Draggable
                      key={category.id}
                      draggableId={category.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: draggingIndex === index ? '1px solid #D9D9D9' : 'none', // Bordure visible uniquement sur l'élément en cours de glissement
                            borderBottom: '1px solid #D9D9D9', // Bordure visible uniquement sur l'élément en cours de glissement
                            height: "58px",
                            backgroundColor: "white",
                            transition: 'box-shadow 0.2s ease',


                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                            onMouseEnter={() => setHoveredId(category.id)}
                            onMouseLeave={() => setHoveredId(null)}
                          >
                            <DragIcon />
                            <Checkbox
                              sx={{ p: "5px" }}
                              color='secondary'
                              checked={category.visible}
                              onChange={() => handleChange(category.id)}
                            />
                            <Box sx={{ height: '58px', display: 'flex', alignItems: 'center' }}>
                              {(hoveredId === category.id || category.id === editCategoryId) ? (
                                <TextField
                                  inputRef={category.id === editCategoryId ? textFieldRef : null}
                                  value={category.title}
                                  onChange={(e) => handleEditText(category.id, e.target.value)}
                                  variant="outlined"
                                  size="small"
                                  sx={{ width:"310px",height: '40px', margin: 0 }}
                                />
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: 16,
                                    flexGrow: 1,
                                    padding: '8px 14px',
                                    cursor: 'pointer',
                                    lineHeight: '40px'
                                  }}
                                >
                                  {category.title}
                                </Typography>
                              )}
                            </Box>
                          </div>
                          <div style={{ width: '48px', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {typeof category.id === 'string' && category.id.startsWith('new_') && (
                              <ButtonIcon
                                onClick={() => handleDeleteCategory(category.id)}
                              >
                                <DeleteOutlineIcon />
                              </ButtonIcon>
                            )}
                          </div>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center", height: "91px", borderTop: "solid 1px #D9D9D9" }}>
          <ButtonCustom
            onClick={handleSave}
            variant="contained"
            disabled={isUpdating}
            startIcon={isUpdating ? (
              <CircularProgress
                size={20}
                sx={{
                  color: 'white',
                }}
              />
            ) : null}
          >
            {isUpdating ? t('LOADING') : t('ADD_BUSINESS_SETTING_SAVE_BUTTON')}
          </ButtonCustom>
        </DialogActions>

      </Dialog>
    </>
  );
};