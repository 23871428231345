import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import ButtonCustom from 'components/ButtonCustom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { inviteMember, updatePermission } from 'services/business-setting-service';
import { IInviteUser } from 'utils/contracts';
import { user_authorizations } from 'utils/Formatter';
import { getErrorHtmlContent } from 'utils/utils';

const textFieldStyles = {
  '& .MuiInputBase-root': {
    height: '50px', // Custom height
    padding: '10px 0px', // Adjust padding as needed
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 13px) scale(1)', // Adjust label position
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -10px) scale(0.75)',
  },
};

const MemberInviteForm = ({ members, refetch }: any) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const { businessId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const onClickHandler = async (e: any) => {
    try {
      const existingUser = members.find(user => user.email === email);
      if (existingUser) {
        // Update the user's role
        await updatePermission(existingUser.id, role, businessId);
        enqueueSnackbar(t('BUSINESS_EDIT_SETTING_MEMBER_SUCESS'), { variant: 'success' });
      } else {
        setisLoading(true);
        e.preventDefault();
        const data = await inviteMember({
          email,
          authorization: role,
          organizationId: businessId,
        } as IInviteUser);
        if (data.status === 200) {
          enqueueSnackbar(t('BUSINESS_STAFF_INVITE_SUCESS'), {
            variant: 'success',
          });
          refetch();
          setisLoading(false);
        }
      }

      refetch();
      setisLoading(false);
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };
  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'center', alignItems:"flex-end" , flexWrap:"wrap"}}>
      <TextField
        variant='outlined'
        size='small'
        sx={{...textFieldStyles , width: {sm:'300px', xs:"200px"}}}
        name='email'
        label={t('BUSINESS_SETTING_MEMBER_EMAIL')}
        id='email'
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete='off'
        autoCorrect='off'
      />
      <FormControl sx={textFieldStyles}>
        <TextField
          select
          label={t('BUSINESS_SETTING_MEMBER_PERMISSION')}
          value={role}
          onChange={(e) => setRole(e.target.value)}
          variant='outlined'
          size='small'
          sx={{ width:"200px" , height: '50px' }}
        >
          {user_authorizations.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {t(option.label)}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      <ButtonCustom
        color="primary"
        type="submit"
        variant="contained"
        onClick={onClickHandler}
      >
        {t('BUSINESS_SETTING_MEMBER_ADD_BUTTON')}
      </ButtonCustom>
    
    



    </Grid>
  );
};

export default MemberInviteForm;