import { Box } from '@mui/material';
import ButtonCustom from 'components/ButtonCustom';
import PasswordTextField from 'components/PasswordTextField';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorHtmlContent } from 'utils/utils';

const AdminLogin = () => {
  const [password, setPassword] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [touched, setTouched] = useState<any>({});
  const [, setsubmitinitiated] = useState(false);
  const { t } = useTranslation();
  const { adminLogin } = useContext(UseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const validateForm = () => {
    let errors: any = {};
    let formIsValid = true;

    if (!password) {
      formIsValid = false;
      errors['password'] = 'Password Is Required';
    }

    setErrors(errors);
    return formIsValid;
  };

  useEffect(() => {
    touched['password'] && validateForm();
  }, [password]);

  const LoginHandler = async (e: any) => {
    e.preventDefault();
    setsubmitinitiated(true);
    if (validateForm()) {
      try {
        setisLoading(true);
        e.preventDefault();
        const data = {
          password,
        };
        await adminLogin(data);
        setisLoading(false);
      } catch (error: any) {
        if (error) {
          enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
          console.log('onError', error);
        }
        setisLoading(false);
      }
    }
  };

  return (
    <Box className='form-container'>
      <form
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <PasswordTextField
          name='password'
          label={t('LOGIN_PASSWORD')}
          fullWidth
          sx={{ marginTop: '2rem','& .MuiInputBase-formControl': { letterSpacing: "4px"  }}} 
          variant='standard'
          value={password}
          onChange={(e: any) => {
            setTouched({ [e.target.name]: true });
            setPassword(e.target.value);
          }}
        />

        <Box
          sx={{
            marginTop: '4rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ButtonCustom
            
            color='primary'
            type='submit'
            variant='contained'
            onClick={LoginHandler}
          >
            {t('LOGIN_BTN_TEXT')}
          </ButtonCustom>
        </Box>
      </form>
    </Box>
  );
};

export default AdminLogin;
