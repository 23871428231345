import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import { AxiosResponse } from 'axios';
import ButtonIcon from 'components/ButtonIcon';
import CustomTableCell from 'components/CustomTableCell';
import { CustomTableFontCell } from 'components/CustomTableFontCell';
import GenericDialog from 'components/GenericDialog';
import { StyledTableSortLabel } from 'components/TableSortedLabel';
import UseAuthContext from 'context/AuthContext';
import { debounce } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getOrganisationLists, getOrganisationListsCSV } from 'services/admin-organisations';
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import { IErrorResponse } from 'utils/contracts';
import {
  getErrorHtmlContent
} from 'utils/utils';
import OrganisationFilter from './OrganisationFilter';

const TableRowWithHover = ({ index, item, handleViewBusinessPage, setOrganisation, navigate, data, t, setAnchorElMenu, setMenuOrgId, menuOrgId, anchorElMenu, handleCloseMenu, deleteOrganisationConfirmation }) => {
  const [hover, setHover] = useState(false); // New state for hover

  return (
    <TableRow
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        cursor: 'pointer',
      }}
      key={item.id}
    >
      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)} width={"60px"}>
        {index}
      </CustomTableCell>
      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)} >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
            width: "240px"
          }}
        >
          {item.image_url ? (
            <img src={item.image_url} width="30px" />
          ) : null}
          {item.company_name}
        </Box>
      </CustomTableCell>
      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)}>
        <span className="data">
        {item.totalPoints || 0}
        </span>
      </CustomTableCell>

      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)}>
        {item.projectCount}
      </CustomTableCell>
      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)}>
        <span className="data">
        {item.totalCredits || 0}
        </span>
      </CustomTableCell>
      <CustomTableCell width={"150px"} onClick={(e) => handleViewBusinessPage(e, item)}>
        <div style={{ display: "flex", gap: "10px" }}>
          <span className="data">
            {moment
              .utc(item.updatedAt)
              .local()
              .format("DD/MM/YY")}
          </span>
          <span className="time" >
            {moment.utc(item.updatedAt).local().format("HH:mm")}
          </span>
        </div>
      </CustomTableCell>
      <CustomTableCell >
        <ButtonIcon
          isActive={menuOrgId === item.id}
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorElMenu(event.currentTarget);
            setMenuOrgId(item.id);
          }}
        >
          <MoreHorizIcon fontSize='medium' />
        </ButtonIcon>
        <Menu
          id={`menu-${item.id}`}
          open={menuOrgId === item.id}

          anchorEl={anchorElMenu}
          onClose={handleCloseMenu}
          sx={{ fontSize: "14px" }}
        >
          <div>
            <MenuItem onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/edit/business/${item.id}`);
            }}>{t('BUSINESS_MENU_EDIT')}</MenuItem>
            <MenuItem onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOrganisation(
                data.find((x: any) => x.id == item.id)
              );
              navigate(`/admin/business/${item.id}/projects`);
            }}>{t('BUSINESS_MENU_VIEW')}</MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                deleteOrganisationConfirmation(item);
              }}>{t('BUSINESS_MENU_DELETE')}</MenuItem></div>
        </Menu>
      </CustomTableCell>
    </TableRow>
  );
};

const AdminDashboard = () => {
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = useState('updatedAt');
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [openDeletePopUp, setOpenDeletePopUp] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [menuOrgId, setMenuOrgId] = useState<string | null>(null);
  const [businessName, setBusinessName] = useState<any>([]);
  const { setOrganisation } = React.useContext(UseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    data: organizationsData,
    isLoading,
  } = useQuery(
    ['GetOrganisations', page, pageSize, sortOrder, sortBy, searchValue],
    () => getOrganisationLists({
      page,
      pageSize,
      sortOrder,
      sortBy,
      search: searchValue || null
    }),
    {
      keepPreviousData: true,
      staleTime: 30000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  // Précharger la page suivante
  useEffect(() => {
    if (organizationsData?.paging.total > page * pageSize) {
      const nextPage = page + 1;
      queryClient.prefetchQuery(
        ['GetOrganisations', nextPage, pageSize, sortOrder, sortBy, searchValue],
        () => getOrganisationLists({
          page: nextPage,
          pageSize,
          sortOrder,
          sortBy,
          search: searchValue || null
        })
      );
    }
  }, [page, organizationsData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, newOrderBy: string) => {
    const isAsc = sortBy === newOrderBy && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(newOrderBy);
  };

  const debouncedSearch = debounce((value: string) => {
    setSearchValue(value);
    setPage(1);
  }, 300);

  const deleteMutation = useMutation({
    mutationFn: async (orgId: string) => {
      const response = await axiosInstance.delete<any, AxiosResponse<any>>(
        `${API_PATHS.ADMIN_DELETE_ORGANIZATION}/${orgId}`
      );
      return response;
    },
    onSuccess() {
      setOpenDeletePopUp(false);
      queryClient.invalidateQueries(['GetOrganisations']);
      enqueueSnackbar(t('DELETE_ORGANIZATION_SUCCESS_MESSAGE'), {
        variant: 'success',
      });
    },
    onError(error: IErrorResponse, variables, context) {
      setOpenDeletePopUp(false);
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error, variables, context);
      }
    },
  });

  const deleteOrganisationHandler = async (orgID: any) => {
    try {
      await deleteMutation.mutateAsync(orgID);
    } catch (error: any) { }
  };

  const deleteOrganisationConfirmation = (org: any) => {
    setAnchorEl(org.id);
    setBusinessName(org.company_name)
    setOpenDeletePopUp(true);

  };

  const handleViewBusinessPage = (e: React.MouseEvent<unknown>, item: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOrganisation(
      organizationsData.data.find((x: any) => x.id == item.id)
    );
    navigate(`/admin/business/${item.id}/projects`);
  }

  const handleClose = () => {
    setOpenDeletePopUp(false);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setMenuOrgId(null);
  };

  const handleDownloadBusinessData = async () => {
    try {
      const response = await getOrganisationListsCSV();

      const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `business_data_${moment().format('YYYY-MM-DD')}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <OrganisationFilter
          handleSearch={debouncedSearch}
          handleDownloadBusinessData={handleDownloadBusinessData}
        />
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead >
            <CustomTableFontCell
              sortDirection={sortBy === "index" ? sortOrder : false}
            >
              <StyledTableSortLabel
                active={sortBy === "index"}
                direction={sortBy === "index" ? sortOrder : "asc"}
                onClick={(event) => handleRequestSort(event, "index")}
              >
                {t('REVISION_INDEX_COLUMN')}
                {sortBy === "index" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>        {/* <TableCell>{t('ADMIN_DASHBOARD_NAME')}</TableCell> */}
            <CustomTableFontCell
              sortDirection={sortBy === "company_name" ? sortOrder : false}
            >
              <StyledTableSortLabel
                active={sortBy === "company_name"}
                direction={sortBy === "company_name" ? sortOrder : "asc"}
                onClick={(event) => handleRequestSort(event, "company_name")}
              >
                {t("ADMIN_DASHBOARD_NAME")}
                {sortBy === "company_name" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell sortDirection={sortBy === "totalPoints" ? sortOrder : false}>
              <StyledTableSortLabel
                active={sortBy === "totalPoints"}
                direction={sortBy === "totalPoints" ? sortOrder : "asc"}
                onClick={(event) => handleRequestSort(event, "totalPoints")}
              >
                {t("ADMIN_DASHBOARD_PURCHASED")}
                {sortBy === "totalPoints" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell sortDirection={sortBy === "projectCount" ? sortOrder : false}>
              <StyledTableSortLabel
                active={sortBy === "projectCount"}
                direction={sortBy === "projectCount" ? sortOrder : "asc"}
                onClick={(event) => handleRequestSort(event, "projectCount")}
              >
                {t("ADMIN_DASHBOARD_TOTAL_PROJECTS")}
                {sortBy === "projectCount" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell sortDirection={sortBy === "credit" ? sortOrder : false}>
              <StyledTableSortLabel
                active={sortBy === "totalCredits"}
                direction={sortBy === "totalCredits" ? sortOrder : "asc"}
                onClick={(event) => handleRequestSort(event, "totalCredits")}
              >
                {t("ADMIN_DASHBOARD_CREDITS")}
                {sortBy === "totalCredits" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>

            <CustomTableFontCell
              sortDirection={sortBy === "updatedAt" ? sortOrder : false}
            >
              <StyledTableSortLabel
                active={sortBy === "updatedAt"}
                direction={sortBy === "updatedAt" ? sortOrder : "asc"}
                onClick={(event) => handleRequestSort(event, "updatedAt")}
              >
                {t("ADMIN_DASHBOARD_DATE")}
                {sortBy === "updatedAt" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell align="right"></CustomTableFontCell>
          </TableHead>
          <TableBody>
            {organizationsData?.data.map((item, index) => (
              <TableRowWithHover
                key={item.id}
                index={(page - 1) * pageSize + index + 1}
                item={item}
                handleViewBusinessPage={handleViewBusinessPage}
                setOrganisation={setOrganisation}
                navigate={navigate}
                data={organizationsData.data}
                t={t}
                setAnchorElMenu={setAnchorElMenu}
                setMenuOrgId={setMenuOrgId}
                menuOrgId={menuOrgId}
                anchorElMenu={anchorElMenu}
                handleCloseMenu={handleCloseMenu}
                deleteOrganisationConfirmation={deleteOrganisationConfirmation}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={organizationsData?.paging.total || 0}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('MUI_ROWS_PER_PAGE')}
        labelDisplayedRows={({ from, to, count }) => 
          t('MUI_PAGINATION_RANGE', { 
            from: from + 1, 
            to: Math.min(to, count), 
            count 
          })}
      />
      <GenericDialog
        open={openDeletePopUp}
        onClose={handleClose}
        title={`${businessName}`}
        onConfirm={(e) => deleteOrganisationHandler(anchorEl)}
        confirmButtonText={t("ORGANISATION_DELETE_YES")}
        cancelButtonText={t("ORGANISATION_DELETE_NO")}
        isLoading={deleteMutation.isLoading}
      >
        {t("ORGANISATION_DELETE_POPUP_DESCRIPTION")}
      </GenericDialog>
    </Box>
  );
};

export default AdminDashboard;