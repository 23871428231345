import { Box, Typography } from '@mui/material';
import Chart from 'components/Chart';
import { Dimension, DimensionFilter } from 'components/DimensionFilter';
import LayersTable from 'components/DimensionTable';
import GenericDialog from 'components/GenericDialog';
import Spinner from 'components/Spinner';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { GetLayoutByProjectId, resetLayout, updateLayoutConfigByProjectId } from 'services/parallel_coordinate-service';
import { getFeatures } from 'services/revisions';
import { colorSet } from 'utils/Formatter';

const maxHeaderWidth = 10;

function RevisionGrid({ isGraphView, revisionData }) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { projectId } = useParams();
	const queryClient = useQueryClient();
	const [openDialogReset, setOpenDialogReset] = useState(false);
	const [open, setOpen] = useState(false);
	const [colorSets, setColorSets] = useState(colorSet);
	const [maxId, setMaxId] = useState(0);

	const [overRow, setOverRow] = useState(null);
	const [brushedData, setBrushedData] = useState([]);
	const [tempschema, setTempschema] = useState([]);
	const [brushTempsData, setBrushTempData] = useState([]);
	const [columnConfig, setColumnConfig] = useState([]);
	const [dimensionConfig, setDimensionConfig] = useState({});
	const [isDimensionsHidden, setIsDimensionsHidden] = useState<string[]>([]);
	const { data: features, isLoading: isFeaturesLoading } = useQuery('features', getFeatures);
	const { data: layoutData, isLoading: isLayoutLoading, error, refetch } = useQuery(['layout', projectId], () => GetLayoutByProjectId(projectId));
	const [processedData, setProcessedData] = useState(false);
	const updateLayerMutation = useMutation(
		({ projectId, layout }: { projectId: string; layout: any }) => updateLayoutConfigByProjectId(projectId, layout),
		{
			onSuccess: (data) => {
				//setUpdateData(data)
				enqueueSnackbar(t('LAYOUT_SUCCESS_UPDATE'), {
					variant: 'success',
				});
				queryClient.invalidateQueries('layers');

			},
		}
	);

	const resetLayoutMutation = useMutation(
		() => resetLayout(projectId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['layout', projectId]);
				refetch()
				enqueueSnackbar(t('LAYOUT_RESET_SUCCESS'), {
					variant: 'success',
				});
				setOpenDialogReset(false);
			},
			onError: (error) => {
				console.error('Erreur lors de la réinitialisation du layout:', error);
				enqueueSnackbar(t('LAYOUT_RESET_ERROR'), {
					variant: 'error',
				});
			},
		}
	);

	const reloadData = () => {
		setBrushedData([])
	};

	const handleUpdateLayout = (projectId: string, updatedCategories: any, updatedTempSchema: any) => {
		const transformedLayers = updatedTempSchema.reduce((acc, item) => {
			const layer = {};
			Object.entries(item).forEach(([key, value]: any) => {
				if (key !== 'id' && key !== 'idx') {
					if (key === 'rating') {
						// Convertir l'index numérique en lettre correspondante
						layer[key] = {
							value: colorSet[value]?.key || value.toString(),
							category: null
						};
					} else {
						layer[key] = {
							value: value.toString(),
							category: key.split(':').slice(1, -1).join(':')
						};
					}
				}
			});
			acc[item.id] = layer;
			return acc;
		}, {});
		// Mise à jour de layoutData avec les nouvelles couches
		const updatedLayoutData = {
			...layoutData,
			columns: updatedCategories,
			layers: transformedLayers
		};

		// Appel de la mutation avec les données mises à jour
		updateLayerMutation.mutate({ projectId, layout: updatedLayoutData });
	};
	const handleResetLayout = () => {
		resetLayoutMutation.mutate();
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Update brush data from brushed
	const updateBrushedData = (data, e) => {
		if (
			/* data.length > 0 && */
			e !== undefined &&
			e.selection &&
			!(e.selection.raw.length == 0 && e.selection.scaled.length == 0)
		) {
			const brushedIds = data.map((item: { id: number }) => item.id);
			setBrushTempData(brushedIds)
			//setTempschema(prevSchema => prevSchema.filter(item => brushedIds.includes(item.id)));
		}
	};

	// Update Mouseover Row
	const updateOverRow = (row) => {
		setOverRow(row);
	};

	// Reset brush
	const resetBrush = () => {
		setBrushedData([]);
		setBrushTempData([]);

	};

	const sortChartAxis = (e) => {
		let tempColorSets = [...colorSets];
		tempColorSets.reverse();
		setColorSets(tempColorSets);
	};

	const handleCategoryToggle = (updatedCategories: Dimension[]) => {
		setColumnConfig(updatedCategories);
		const newDimensionConfig = new Map<string, any>();
		const newHiddenDimensions: string[] = [];
		const updatedCategoryIds = new Set(updatedCategories.map(cat => cat.id));

		let updatedTempSchema = [...tempschema];

		updatedTempSchema = updatedTempSchema.map(item => {
			const newItem = { ...item };
			Object.keys(newItem).forEach(key => {
				if (!updatedCategoryIds.has(key) && key !== 'id' && key !== 'idx') {
					delete newItem[key];
				}
			});
			return newItem;
		});

		updatedCategories.forEach(category => {
			if (dimensionConfig[category.id]) {
				newDimensionConfig.set(category.id, {
					...dimensionConfig[category.id],
					title: category.title
				});
			} else {
				// Nouvelle colonne ajoutée
				newDimensionConfig.set(category.id, {
					type: 'string',
					title: category.title,
				});

				updatedTempSchema = updatedTempSchema.map(item => ({
					...item,
					[category.id]: item[category.id] || "undefined" // Au lieu de "N/A"
				}));
			}

			if (!category.visible) {
				newHiddenDimensions.push(category.id);
			}
		});

		setTempschema(updatedTempSchema);
		setDimensionConfig(Object.fromEntries(newDimensionConfig));
		setIsDimensionsHidden(newHiddenDimensions);
		handleUpdateLayout(projectId, updatedCategories, updatedTempSchema);

	};

	useEffect(() => {
		setProcessedData(false)
		if (layoutData) {
			const resultat = Object.entries(layoutData.layers).map(([id, data]: [string, any]) => {
				const entry = {
					id: id,
					idx: revisionData ? revisionData.find(rev => rev.id === id)?.index || 0 : 0
				};
				Object.entries(data).forEach(([key, value]: [string, any]) => {
					if (key === 'rating') {
						entry[key] = colorSets.findIndex((c) => c.key === value.value) || 0;
					} else if (key !== 'idx') {
						entry[key] = value.value;
					}
				});

				return entry;
			});




			const structure: { [key: string]: any } = {};
			layoutData.columns.forEach((colonne, index) => {
				if (colonne.id === 'rating') {
					structure[colonne.id] = {
						type: "string",
						title: colonne.title,
						tickFormat: function (d) {
							return colorSets[d]?.key;
						},
					};
				} else {
					structure[colonne.id] = { title: colonne.title, type: 'string' };
				}
			});
			structure.idx = {
				orient: "right",
				title: "#",
				type: "number",
				tickFormat: function (d) {
					return Math.round(d)
				},
				tickPadding: 1,
			};

			setTempschema(resultat);
			setColumnConfig(layoutData.columns)
			setDimensionConfig(structure);
			setMaxId(resultat[resultat.length-1].idx);
			setProcessedData(true);

		}
	}, [layoutData, colorSets]);

	useEffect(() => {
		if (layoutData && processedData) {
			const hiddenDimensions = columnConfig
				.filter(col => !col.visible)
				.map(col => col.id);
			setIsDimensionsHidden(hiddenDimensions);
		}
	}, [layoutData, processedData, columnConfig]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				resetBrush();
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [resetBrush]);
	
	if (isFeaturesLoading || (isLayoutLoading && !error)) {
		return <div><Spinner /></div>
	}

	if (error) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "50vh",
				}}
			>
				<img
					src={require("../../assets/NoProject.svg").default}
					style={{ marginBottom: "48px" }}
					alt=""
				/>
				<Typography variant="h5" fontWeight="bold" fontSize="24px">
					{t("NO_LAYER_HEADING_1")}
				</Typography>
		
			</Box>
		);
	}

	if (error) {
		enqueueSnackbar(t(`${error}`), {
			variant: 'error',
		});
	};

	if (!features.graphAllowed) {
		return <div>{t('Graph is Disabled')}</div>
	}

	return (
		<div className="App">
			
			<DimensionFilter
				categories={columnConfig}
				onToggle={handleCategoryToggle}
				open={open}
				handleClose={handleClose}
			/>
			{/* chart */}
			{isGraphView && processedData && isDimensionsHidden.length >= 0 && (
				<Chart
					data={tempschema}
					colorSet={colorSets}
					updateBrushedData={updateBrushedData}
					brushedData={brushedData}
					mouseOverRow={overRow}
					maxHeaderWidth={maxHeaderWidth}
					dimensions={dimensionConfig}
					maxId={maxId}
					reloadChart={reloadData}
					sortChartAxis={sortChartAxis}
					isDimensionsHidden={isDimensionsHidden}
				/>
			)}
			{/* grid */}
			<LayersTable
				tempschema={tempschema}
				columns={columnConfig}
				brushTempsData={brushTempsData}
				colorSet={colorSet}
				onRowHover={updateOverRow}
				openSettings={open}
				handleOpenSettings={() => setOpen(true)}
				handleOpenResetDialog={() => setOpenDialogReset(true)}
				openResetDialog={openDialogReset}
				handleResetBrush={() => resetBrush()}
				onUpdateTempschema={(updatedTempschema) => {
					setTempschema(updatedTempschema)
					handleUpdateLayout(projectId, columnConfig, updatedTempschema)
				}}
			/>
			<GenericDialog
				open={openDialogReset}
				onClose={() => setOpenDialogReset(false)}
				title={t('LAYOUT_RESET_TITLE')}
				onConfirm={handleResetLayout}
				confirmButtonText={t('REVISION_UNLOCK_YES')}
				cancelButtonText={t('REVISION_UNLOCK_NO')}
			>
				{t('LAYOUT_RESET_DESCRIPTION')}?
			</GenericDialog>
		</div>
	);
}

export default RevisionGrid;