import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonCustom from 'components/ButtonCustom';
import PasswordTextField from 'components/PasswordTextField';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IUserLoginRequest } from 'utils/contracts';
import { getErrorHtmlContent } from 'utils/utils';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const { login } = useContext(UseAuthContext);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const LoginHandler = async (e: any) => {
    try {
      setisLoading(true);
      e.preventDefault();
      const data: IUserLoginRequest = {
        email,
        password,
      };
      await login(data);
      setisLoading(false);
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  return (
    <Box >
      <form
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: "45px",
        }}
      >
        <TextField
          name='email'
          label={t('LOGIN_EMAIL')}
          type='email'
          fullWidth
          variant='standard'
          helperText={t('LOGIN_EMAIL_HELPTEXT')}
          sx={{mb:-1}}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordTextField

          name='password'
          label={t('LOGIN_PASSWORD')}
          fullWidth
          sx={{  '& .MuiInputBase-formControl': { letterSpacing: "4px",pb:"2px",  }}} 
          variant='standard'
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Box
          dir={'ltr'}
          sx={{
            mt:2,
            gap:"10px",
            display: 'flex',
            flexWrap:"wrap",
            flexDirection: { xs: 'column-reverse', sm: 'row' }, 
            alignItems: { xs: 'center' }, 
            justifyContent: 'space-between',
          }}
        >  
        <ButtonCustom
          color='info'
          variant='contained'
          className='secondary-btn'
          onClick={(e: any) => {
            e.preventDefault();
            navigate('/forgot');
          }}
        >
            {t('LOGIN_FORGOT_PASSWORD_BTN_TEXT')}
          </ButtonCustom>
          <ButtonCustom
            color='primary'
            type='submit'
            variant='contained'
            onClick={LoginHandler}
          >
            {t('LOGIN_BTN_TEXT')}
          </ButtonCustom>
        
        </Box>
      </form>
    </Box>
  );
};

export default Login;
