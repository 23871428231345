import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { AxiosResponse } from 'axios';
import { CustomTableFontCell } from 'components/CustomTableFontCell';
import GenericDialog from 'components/GenericDialog';
import RevisionRowData from 'components/RevisionRowData';
import Spinner from 'components/Spinner';
import { TypographyLocale } from 'components/TypographyLocale';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { GetUserOrganization } from 'services/auth-service';
import { getProjectById } from 'services/project-service';
import { unlockRevision } from 'services/revisions';
import { axiosInstance } from 'utils/AxiosInstance';
import { calcTotalAccountPoints } from 'utils/Points';
import { API_PATHS, USER_ROLES } from 'utils/constants';
import { IErrorResponse, Order } from 'utils/contracts';
import { getComparator, getErrorHtmlContent, stableSort } from 'utils/utils';
import { StyledTableSortLabel } from "../Projects/ProjectListView";
let DEFAULT_ORDER = 'desc',
  DEFAULT_ORDER_BY = 'index';


const RevisionList = ({ data, isLoading, refetch }) => {
  const [page, setPage] = React.useState(0);
  const [isUnlockLoading, setIsUnlockLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [openCredit, setOpenCredit] = React.useState(false);
  const [openDeletePopUp, setOpenDeletePopUp] = React.useState(false);
  const [deleteRevisionID, setDeleteRevisionID] = React.useState('');
  const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER as Order);
  const [orderBy, setOrderBy] = React.useState<string>(DEFAULT_ORDER_BY);
  const { projectId, businessId } = useParams();
  const [selectedRevision, setSelectedRevision] = React.useState<any>();
  const [revisionData, setRevisionData] = React.useState<any[]>([]);
  
  const {
    role: authorization,
    setOrganisationId,
    getOrganisation,
    organisationId,
  } = useContext(UseAuthContext);
  const isAdmin = authorization === USER_ROLES.ADMIN;

  const {
    data: projectData = { name: '', image_url: '' },
    isLoading: isProjectLoading,
  } = useQuery(
    ['GetProjectByID', projectId],
    async () => await getProjectById(projectId ?? '')
  );

  const { data: organizationData, isLoading: isOrganizationLoading } = useQuery(
    ['getOrganisationbyId', businessId],
    async () =>  await GetUserOrganization(businessId || ''),
    {enabled: !!businessId,staleTime: 0}
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [view, setView] = useState('List');
  const { enqueueSnackbar } = useSnackbar();

  const deleteRevisionMutation = useMutation({
    mutationFn: async (revisionID: string) => {
      const response = await axiosInstance.delete<any, AxiosResponse<any>>(
        `${API_PATHS.DELETE_REVISION}/${revisionID}`
      );
      return response;
    },
    onSuccess() {
      refetch();
      setDeleteRevisionID('');
      setOpenDeletePopUp(false);
      enqueueSnackbar(t('DELETE_REVISION_SUCCESS_MESSAGE'), {
        variant: 'success',
      });
    },
    onError(error: IErrorResponse, variables, context) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error, variables, context);
      }
    },
  });

  const deleteRevision = async (e: any, revisionID: any) => {
    try {
      await deleteRevisionMutation.mutateAsync(revisionID);
    } catch (error: any) { }
  };

  useEffect(() => {
    if (data) {
      let rowsOnMount = stableSort(
        data,
        getComparator(DEFAULT_ORDER as Order, DEFAULT_ORDER_BY)
      );
      setRevisionData(rowsOnMount);
    }
  }, [data]);

  useEffect(() => {
    if (businessId && isAdmin) {
      setOrganisationId(businessId);
    }
  
  }, [businessId, setOrganisationId, authorization, organizationData]);

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: string) => {
      const isAsc = orderBy === newOrderBy && order === 'asc';
      const toggledOrder = isAsc ? 'desc' : 'asc';
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        data,
        getComparator(toggledOrder, newOrderBy)
      );
      setRevisionData(sortedRows);
    },
    [order, orderBy, page, rowsPerPage, revisionData]
  );


  const createSortHandler =
    (newOrderBy: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, newOrderBy);
    };

  const handleClickOpen = (selectedRevision: any) => {
    setSelectedRevision(selectedRevision);
    setOpen(true);
  };

  const topUp = (e: any) => {
    e.preventDefault();

    if (authorization === USER_ROLES.ADMIN) {
      navigate(`/edit/business/${businessId}`);
    } else {
      navigate('/pricing');
    }
  };

  const handleClickCreditPopUpOpen = () => {
    setOpenCredit(true);
  };

  const handleClickDeletePopUpOpen = (revisionID: any) => {
    setOpenDeletePopUp(true);
    setDeleteRevisionID(revisionID);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCredit(false);
    setOpenDeletePopUp(false);
    setDeleteRevisionID('');
  };

  const totalPoints = useMemo(() => {
    if (organizationData) {
      return calcTotalAccountPoints(organizationData);
    }
    return 0;
  }, [organizationData]);

  if (
    isProjectLoading ||
    isLoading ||
    isOrganizationLoading 
  ) {
    return <Spinner></Spinner>;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUnlockRevision = async () => {
    try {
      setIsUnlockLoading(true);
      const result = await unlockRevision(
        selectedRevision?.id,
        projectId,
        organizationData?.id
      );

      if (result.status === 200) {
        setIsUnlockLoading(false);
        await getOrganisation(businessId || organisationId);
        refetch();
      }
      setOpen(false);
    } catch (error: any) {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
      setIsUnlockLoading(false);
      console.log('onError', error);
      setOpen(false);
    }
  };

  return (
    <>
      {view === 'List' && (
         data.length < 1 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <img
              src={require("../../assets/NoProject.svg").default}
              style={{ marginBottom: "48px"}}
              
              alt=""
            />
            <TypographyLocale variant="h2">
              {t("NO_REVISION_HEADING_1")}
            </TypographyLocale>
          {/*   <TypographyLocale variant="h3" fontSize="18px">
              {t("NO_REVISION_HEADING_2")}
            </TypographyLocale> */}
          </Box>
        ) : (
          <TableContainer>
          
              <Table size="small" >
                <TableHead >
                  <CustomTableFontCell />
                  <CustomTableFontCell
                    sx={{ minWidth: "70px" }}
                    sortDirection={orderBy === 'index' ? order : false}
                  >
                    <StyledTableSortLabel
                      active={orderBy === 'index'}
                      direction={orderBy === 'index' ? order : 'asc'}
                      onClick={createSortHandler('index')}
                    >
                      {t('REVISION_INDEX_COLUMN')}
                      {orderBy === 'index' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </CustomTableFontCell>
                  <CustomTableFontCell
                    sortDirection={orderBy === 'file_name' ? order : false}
                  >
                    <StyledTableSortLabel
                      sx={{ width: '120px' }}
                      active={orderBy === 'file_name'}
                      direction={orderBy === 'file_name' ? order : 'asc'}
                      onClick={createSortHandler('file_name')}
                    >
                      {t('REVISION_File_COLUMN')}
                    </StyledTableSortLabel>
                  </CustomTableFontCell>
                  <CustomTableFontCell
                    sortDirection={orderBy === 'createdBy' ? order : false}
                  >
                    <StyledTableSortLabel
                      sx={{ width: '120px' }}
                      active={orderBy === 'createdBy'}
                      direction={orderBy === 'createdBy' ? order : 'asc'}
                      onClick={createSortHandler('createdBy')}
                    >
                      {t('REVISION_CREATED_BY_COLUMN')}
                      {orderBy === 'createdBy' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </CustomTableFontCell>
                  <CustomTableFontCell

                    sortDirection={orderBy === 'updatedAt' ? order : false}
                  >
                    <StyledTableSortLabel
                      active={orderBy === 'updatedAt'}
                      direction={orderBy === 'updatedAt' ? order : 'asc'}
                      onClick={createSortHandler('updatedAt')}
                    >
                      {t('REVISION_UPDATED_DATE')}
                      {orderBy === 'updatedAt' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </CustomTableFontCell>
                  <TableCell
                    sx={{ maxWidth: '40px' }}
                    sortDirection={orderBy === 'report_1045_pass' ? order : false}
                  >
                    <StyledTableSortLabel
                      active={orderBy === 'report_1045_pass'}
                      direction={orderBy === 'report_1045_pass' ? order : 'asc'}
                      onClick={createSortHandler('report_1045_pass')}
                    >
                      {t('REVISION_RATING_GRADE_COLUMN')}
                      {orderBy === 'report_1045_pass' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </TableCell>
                  {projectData.type == "Residential" && <TableCell
                    sx={{ maxWidth: '40px' }}
                    sortDirection={orderBy === 'h_report_pass' ? order : false}
                  >
                    <StyledTableSortLabel
                      active={orderBy === 'h_report_pass'}
                      direction={orderBy === 'h_report_pass' ? order : 'asc'}
                      onClick={createSortHandler('h_report_pass')}
                    >
                      {t('REVISION_H_REPORT_COLUMN')}
                      {orderBy === 'h_report_pass' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </TableCell>}
                  <TableCell
                    sortDirection={orderBy === 'weighed_grade' ? order : false}
                  >
                    <StyledTableSortLabel
                      active={orderBy === 'weighed_grade'}
                      direction={orderBy === 'weighed_grade' ? order : 'asc'}
                      onClick={createSortHandler('weighed_grade')}
                    >
                      {t('REVISION_NOTES_GRADE')}
                      {orderBy === 'weighed_grade' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </StyledTableSortLabel>
                  </TableCell>
                  <CustomTableFontCell>{t('REVISION_NOTES_RATING')}</CustomTableFontCell>
                  <CustomTableFontCell></CustomTableFontCell>
                  <CustomTableFontCell></CustomTableFontCell>
                </TableHead>
                <TableBody >
                  {revisionData
                    .map((data: any, index: number) => {
                      return {
                        ...data
                      };
                    })
                    .map((data: any) => {
                      return (
                        <RevisionRowData
                          props={data}
                          key={data.id}
                          index={data.index}
                          projectId={projectId}
                          refetchData={refetch}
                          handleClickOpen={handleClickOpen}
                          totalPoints={totalPoints}
                          OrgId={organizationData?.id}
                          hasTrial={organizationData?.hasTrial}
                          projectType={projectData.type}
                          handleClickCreditPopUpOpen={handleClickCreditPopUpOpen}
                          handleClickDeletePopUpOpen={handleClickDeletePopUpOpen}
                        />
                      );
                    })}
                </TableBody>
              </Table>
              {/*  <TablePagination
                sx={{ width: "100%" }}
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('MUI_ROWS_PER_PAGE')}
                labelDisplayedRows={({ from, to, count }) => t('MUI_PAGINATION_RANGE', { from, to, count })}
                getItemAriaLabel={(type) => t(`MUI_PAGINATION_${type.toUpperCase()}_PAGE`)}
              /> */}
            <GenericDialog
              open={open}
              onClose={handleClose}
              title={t('REVISION_UNLOCK_TITLE')}
              onConfirm={handleUnlockRevision}
              confirmButtonText={t('REVISION_UNLOCK_YES')}
              cancelButtonText={t('REVISION_UNLOCK_NO')}
            >
              {t('REVISION_UNLOCK_DESCRIPTION').replace('$$1', selectedRevision?.requiredPoints)}
            </GenericDialog>
            <GenericDialog
              open={openCredit}
              onClose={handleClose}
              title={t('REVISION_CREDIT_ADD_TITLE')}
              onConfirm={topUp}
              confirmButtonText={t('REVISION_CREDIT_ADD_YES')}
              cancelButtonText={t('REVISION_CREDIT_ADD_NO')}
            >
              {t('REVISION_CREDIT_ADD_DESCRIPTION')}
            </GenericDialog>
            <GenericDialog
              open={openDeletePopUp}
              onClose={handleClose}
              title={t('REVISION_DELETE_POPUP_TITLE')}
              onConfirm={() => deleteRevision(null, deleteRevisionID)}
              confirmButtonText={t('REVISION_DELETE_YES')}
              cancelButtonText={t('REVISION_DELETE_NO')}
            >
              {t('REVISION_DELETE_POPUP_DESCRIPTION')}
            </GenericDialog>
          </TableContainer>
        )
      )}
    </>
  );
};

export default RevisionList;
