import Box from '@mui/material/Box';
import ButtonCustom from 'components/ButtonCustom';
import PasswordTextField from 'components/PasswordTextField';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updatePrivateAreaPassword } from 'services/private-area-services';
import { getErrorHtmlContent } from 'utils/utils';

const UserPasswordEdit = () => {
  const { t } = useTranslation();
  const [isLoading, setisLoading] = useState(false);
  const [currentPassword, setcurrentPassword] = useState('');
  const [newpassword, setNewpassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async (e: any) => {
    try {
      e.preventDefault();
      setisLoading(true);
      if (newpassword === confirmpassword) {
        await updatePrivateAreaPassword({
          newPassword: newpassword,
          newPasswordAgain: confirmpassword,
          oldPassword: currentPassword,
        });
        enqueueSnackbar(t('PRIVATE_AREA_PASSWORD_UPDATE_SUCESS'), {
          variant: 'success',
        });
        setisLoading(false);
      } else {
        setisLoading(false);
        enqueueSnackbar(t('PASSWORD_MISMATCH'), {
          variant: 'error',
        });
      }
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  return (
    <Box component='form' sx={{ mx: '2rem', display:"flex", gap:"30px", width:{xs:"100%", md:"480px"} }} className='tab-content-form'>
      <PasswordTextField
        margin='normal'
        fullWidth
        variant='standard'
        id='password'
        name="new_password"
        autoFocus
        helperText=" "

        sx={{ '& .MuiInputBase-formControl': { letterSpacing: "4px"  }}} 

        label={t('USER_EDIT_PASSWORD_CURRENT_PASS')}
        autoComplete='off'
        autoCorrect='off'
        onChange={(e: any) => setcurrentPassword(e.target.value)}
        value={currentPassword}
      />
      <PasswordTextField
        margin='normal'
        fullWidth
        variant='standard'
        id='new_password'
        name='new_password'
        helperText=" "

        sx={{ '& .MuiInputBase-formControl': { letterSpacing: "4px"  }}} 

        label={t('USER_EDIT_PASSWORD_NEW_PASS')}
        autoComplete='off'
        autoCorrect='off'
        onChange={(e: any) => setNewpassword(e.target.value)}
        value={newpassword}
      />
      <PasswordTextField
        margin='normal'
        fullWidth
        variant='standard'
        id='new_password_confirmation'
        name='new_password_confirmation'
        sx={{ '& .MuiInputBase-formControl': { letterSpacing: "4px"  }}} 
        helperText=" "

        label={t('USER_EDIT_PASSWORD_NEW_PASS_CONFIRM')}
        autoComplete='off'
        autoCorrect='off'
        onChange={(e: any) => setConfirmpassword(e.target.value)}
        value={confirmpassword}
      />
      {/* <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2 }}>
        {t('USER_EDIT_PASSWORD_UPDATE_BTN_TEXT')}
      </Button> */}
        <ButtonCustom
          color='primary'
          type='submit'
          variant='contained'
          onClick={(e: any) => handleClick(e)}
        >
          {t('USER_EDIT_PASSWORD_UPDATE_BTN_TEXT')}
        </ButtonCustom>
      

    </Box>
  );
};

export default UserPasswordEdit;
