import axios from 'axios';
import urll from 'url';
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import { IRevisionCreate } from './../utils/contracts';

export const getrevisions = async (projectId: any) => {
  const data = await axiosInstance.get(`${API_PATHS.REVISIONS}`, {
    headers: {
      'x-project-id': projectId,
    },
  });
  return data.data;
};

export const createRevision = async (
  revisionData: IRevisionCreate,
  projectId: any,
  organizationId: any
) => {
  try {
    const data = await axiosInstance.post(
      `${API_PATHS.REVISIONS}`,
      {
        h_report_density: revisionData.h_report_density,
        h_report_percentage: revisionData.h_report_percentage,
        h_report_uvalue: revisionData.h_report_uvalue,
        notes: revisionData.notes,
        simulator: revisionData.simulator,
      },
      {
        headers: {
          'x-project-id': projectId,
          'x-organization-id': organizationId,
        },
      }
    );

    if (data.status === 200 && data.data) {
      const s3PresignedUrlData = await getPresignedURL(
        data.data.id,
        projectId,
        organizationId
      );
      if (s3PresignedUrlData.status === 200 && s3PresignedUrlData.data) {
        const { publicId, url } = s3PresignedUrlData.data;

        await uploadFileToS3(revisionData.file, url, publicId);

        const { protocol, host, pathname } = urll.parse(url);

        const patchRevisionData = {
          files: {
            idf: {
              name: revisionData.file.name,
              url: `${protocol}//${host}${pathname}`,
              publicId: publicId,
            },
          },
        };
        await patchRevisionAfterUpload(
          patchRevisionData,
          data.data.id,
          projectId,
          organizationId
        );
      }
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const updateRevision = async (
  revisionData: IRevisionCreate,
  revisionId: any,
  projectId: any,
  organizationId: any
) => {
  try {
    let updateRevisionData: any = {
      h_report_density: revisionData.h_report_density,
      h_report_percentage: revisionData.h_report_percentage,
      h_report_uvalue: revisionData.h_report_uvalue,
      notes: revisionData.notes,
    };
    if (revisionData.file) {
      const s3PresignedUrlData = await getPresignedURL(
        revisionId,
        projectId,
        organizationId
      );

      if (s3PresignedUrlData.status === 200 && s3PresignedUrlData.data) {
        const { publicId, url } = s3PresignedUrlData.data;
        await uploadFileToS3(revisionData.file, url, publicId);

        const { protocol, host, pathname } = urll.parse(url);

        updateRevisionData.files = {
          idf: {
            name: revisionData.file.name,
            url: `${protocol}//${host}${pathname}`,
            publicId: publicId,
          },
        };
      }
    }

    const data = await axiosInstance.put(
      `${API_PATHS.REVISIONS}/${revisionId}`,
      updateRevisionData,
      {
        headers: {
          'x-project-id': projectId,
          'x-organization-id': organizationId,
        },
      }
    );

    if (data.status === 200 && data.data) {
      return true;
    }
  } catch (error) {
    console.log(error);

    return false;
  }
};

const uploadFileToS3 = async (file: any, presignedURL: any, key: string) => {
  const options = {
    params: {
      Key: key,
      ContentType: 'plain/text',
    },
    headers: {
      'Content-Type': 'plain/text',
      'Content-Length': file.size,
    },
  };

  return await axios.put(presignedURL, file, options);
};

const uploadDSBFileToS3 = async (file: any, presignedURL: any, publicId: string) => {
  const options = {
    params: {
      Key: publicId,
      ContentType: 'application/zip',
    },
    headers: {
      'Content-Type': 'application/zip',
      'Content-Length': file.size,
    },
  };

  return await axios.put(presignedURL, file, options);
};

export const runRevision = async (
  projectId: any,
  revisionId: any,
  organizationId: any
) => {
  return await axiosInstance.patch(
    `${API_PATHS.REVISIONS}/${revisionId}/run`,
    {},
    {
      headers: {
        'x-project-id': projectId,
        'x-organization-id': organizationId,
      },
    }
  );
};

export const getPresignedURL = async (
  revisionId: any,
  projectId: any,
  organizationId: any
) => {
  return await axiosInstance.get(
    `/pipe/${organizationId}/${projectId}/${revisionId}/upload/idf`
  );
};

export const getPresignedURLForDSBFile = async (
  revisionId: any,
  projectId: any,
  organizationId: any
) => {
  return await axiosInstance.get(
    `/pipe/${organizationId}/${projectId}/${revisionId}/upload/dsb`,
    {
      headers: {
        'Content-Type': 'application/zip',
      },
    }
  );
};

const patchRevisionAfterUpload = async (
  data: any,
  revisionId: any,
  projectId: any,
  organizationId: any
) => {
  return await axiosInstance.put(`${API_PATHS.REVISIONS}/${revisionId}`, data, {
    headers: {
      'x-project-id': projectId,
      'x-organization-id': organizationId,
    },
  });
};

export const unlockRevision = async (
  revisionId: any,
  projectId: any,
  organizationId: any
) => {
  return await axiosInstance.patch(
    `${API_PATHS.REVISIONS}/${revisionId}/unlock`,
    {},
    {
      headers: {
        'x-project-id': projectId,
        'x-organization-id': organizationId,
      },
    }
  );
};

export const getRevisionResult = async (
  revisionId: any,
  projectId: any,
  organizationId: any
) => {
  return await axiosInstance.get(
    `${API_PATHS.REVISIONS}/${revisionId}/result`,
    {
      headers: {
        'x-project-id': projectId,
        'x-organization-id': organizationId,
      },
    }
  );
};

export const getRevision = async (
  revisionId: any,
  projectId: any,
  organizationId: any
) => {
  return await axiosInstance.get(`${API_PATHS.REVISIONS}/${revisionId}`, {
    headers: {
      'x-project-id': projectId,
      'x-organization-id': organizationId,
    },
  });
};

export const updateDSBFileToTicket = async (
  projectId: any,
  organizationId: any,
  revisionId: any,
  file: any
) => {
  try {
    let updateRevisionData: any = {};
    if (file) {
      const s3PresignedUrlData = await getPresignedURLForDSBFile(
        revisionId,
        projectId,
        organizationId
      );

      if (s3PresignedUrlData.status === 200 && s3PresignedUrlData.data) {
        const { publicId, url } = s3PresignedUrlData.data;
        await uploadDSBFileToS3(file, url, publicId);

        const { protocol, host, pathname } = urll.parse(url);

        updateRevisionData.files = {
          dsb: {
            name: file.name,
            url: `${protocol}//${host}${pathname}`,
            publicId: publicId,
          },
        };
      }
    }

    const data = await axiosInstance.patch(
      `${API_PATHS.TICKETS}/${revisionId}/open`,
      updateRevisionData,
      {
        headers: {
          'x-project-id': projectId,
          'x-organization-id': organizationId,
        },
      }
    );

    if (data.status === 200 && data.data) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
  
};

export const getFeatures = async () => {
  const response = await axiosInstance.get(`${API_PATHS.FEATURES}`);
  return response.data;
};
