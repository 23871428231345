import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { TypographyLocale } from 'components/TypographyLocale';
import UseAuthContext from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { updatePrivateArea } from 'services/private-area-services';
import UserEmailEdit from './UserEmailEdit';
import UserPasswordEdit from './UserPasswordEdit';
import UserPersonalInfoEdit from './UserPersonalInfoEdit';

const EditUser = () => {
  const [value, setValue] = React.useState('PERSONAL_INFO');
  const { language } = useContext(UseLanguageContext);
  const { t } = useTranslation();
  const { user } = useContext(UseAuthContext);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const updatePrivateDetails = async (data: any) => {
    let updatedDetails = {
      ...user,
      ...data,
    };
    delete updatedDetails.organizationId;
    await updatePrivateArea(updatedDetails);
  };




  return (
    <Box sx={{  }}>
      <Box sx={{ height: "144px", alignContent: "center", mb: "22px",display:"flex", alignItems: "center" }}>
        <TypographyLocale
          language={language}
          variant='h2'
        >
          {t('USER_EDIT_HEADER')}
        </TypographyLocale>
      </Box>
      {/* <Typography variant='h4' sx={{ mb: '1rem' }}>
      </Typography> */}
      <Box sx={{ bgcolor: '#fff' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '30px' }}>
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab label={t('USER_EDIT_PI_HEADER')} value='PERSONAL_INFO' />
              <Tab label={t('USER_EDIT_EMAIL_UPDATE_HEADER')} value='EMAIL' />
              <Tab label={t('USER_EDIT_PASSWORD_UPDATE_HEADER')} value='PASS' />
            </TabList>
          </Box>
          <TabPanel value='PERSONAL_INFO' className='tab-content'>
            <UserPersonalInfoEdit updatePrivateDetails={updatePrivateDetails} />
          </TabPanel>
          <TabPanel value='EMAIL' className='tab-content'>
            <UserEmailEdit updatePrivateDetails={updatePrivateDetails} />
          </TabPanel>
          <TabPanel value='PASS' className='tab-content'>
            <UserPasswordEdit />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default EditUser;
